/* Global Variables */
:root {
  --primary-color: #3a8f85;
  --primary-dark: #2c7269;
  --primary-light: #8cc5bf;
  --secondary-color: #d68c45;
  --secondary-dark: #b87339;
  --secondary-light: #e9b384;
  --dark: #2d3a3a;
  --dark-gray: #4d5c5c;
  --medium-gray: #7e8c8c;
  --light-gray: #d2d8d8;
  --off-white: #f8f7f5;
  --white: #ffffff;
  --success: #739e73;
  --warning: #e6b86a;
  --error: #c17b7b;
  --info: #6a91ab;
}

/* Global Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Container */
.container {
  background-color: var(--off-white);
  min-height: 100vh;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, sans-serif;
  overflow-x: hidden;
}

/* Hero Section */
.hero-section {
  background: linear-gradient(
    120deg,
    var(--primary-dark) 0%,
    var(--primary-color) 100%
  );
  padding: 6rem 0 4rem;
  text-align: center;
  position: relative;
  color: var(--white);
  width: 100%;
  box-shadow: inset 0 -10px 20px rgba(0, 0, 0, 0.1);
}

.hero-content {
  max-width: 100%;
  padding: 0 2rem;
}

.hero-title {
  font-size: 3.25rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.25rem;
  animation: slideIn 0.8s ease-out;
}

.hero-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.5;
  max-width: 700px;
  margin: 0 auto 2rem;
  opacity: 0.95;
  animation: slideIn 0.8s ease-out 0.2s both;
}

.hero-cta {
  display: inline-block;
  padding: 1rem 2.5rem;
  background-color: var(--secondary-color);
  color: var(--white);
  font-size: 1.25rem;
  font-weight: 600;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: slideIn 0.8s ease-out 0.4s both;
}

.hero-cta:hover {
  background-color: var(--secondary-dark);
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Main Container */
.main-container {
  max-width: 100%;
  margin: 0;
  padding: 0 1rem;
}

.main-scroll {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

/* Search Section */
.search-section {
  position: relative;
  z-index: 10;
}

.search-inputs-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2.5rem;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
}

.input-label {
  color: var(--dark);
  font-size: 1.15rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  transition: color 0.3s ease;
}

/* DrugSearchInput Styles */
.drug-search-input {
  position: relative;
  width: 100%;
}

.drug-search-input input {
  width: 100%;
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  font-family: 'Poppins', sans-serif;
  color: var(--dark);
  background-color: var(--white);
  border: 2px solid var(--light-gray);
  border-radius: 12px;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.drug-search-input input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 4px 16px rgba(58, 143, 133, 0.2);
  transform: translateY(-2px);
}

.drug-search-input input::placeholder {
  color: var(--medium-gray);
  opacity: 0.8;
}

.drug-search-input .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--white);
  border-radius: 12px;
  border: 1px solid var(--light-gray);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  margin-top: 0.5rem;
  animation: dropdownFade 0.2s ease-out;
}

.drug-search-input .dropdown-item {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: var(--dark-gray);
  cursor: pointer;
  transition: all 0.2s ease;
}

.drug-search-input .dropdown-item:hover {
  background-color: var(--primary-light);
  color: var(--primary-dark);
}

@keyframes dropdownFade {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Results Section */
.results-container {
  padding: 2rem 0;
}

.results-section-title {
  color: var(--primary-dark);
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 2.5rem;
  text-align: center;
}

.message-card {
  background-color: var(--white);
  border-radius: 16px;
  padding: 3rem;
  text-align: center;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
}

.message-card:hover {
  transform: translateY(-6px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
}

.no-interaction-icon {
  font-size: 3.5rem;
  color: var(--success);
  margin-bottom: 1.5rem;
}

.message-title {
  color: var(--dark);
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.message-text {
  color: var(--medium-gray);
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
}

.suggestions-title {
  color: var(--primary-dark);
  font-size: 1.5rem;
  font-weight: 600;
  margin: 2rem 0 1.5rem;
}

/* Info Section */
.info-section {
  background-color: var(--white);
  border-radius: 16px;
  padding: 3rem;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.06);
  margin: 0 1rem;
}

.info-title {
  color: var(--primary-dark);
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-align: center;
}

.info-text {
  color: var(--medium-gray);
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto 2rem;
}

.info-list {
  list-style: none;
  padding: 0;
  display: grid;
  gap: 1.25rem;
  max-width: 600px;
  margin: 0 auto;
}

.info-list li {
  position: relative;
  padding-left: 2rem;
  font-size: 1.15rem;
  color: var(--dark-gray);
  text-align: left;
}

.info-list li:before {
  content: '✔';
  color: var(--success);
  position: absolute;
  left: 0;
  font-size: 1.25rem;
}

/* DrugSearchInput Modern Styles */
.search-container {
  position: relative;
  width: 100%;
}

.search-input {
  width: 100%;
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  font-family: 'Poppins', sans-serif;
  color: var(--dark);
  background-color: var(--white);
  border: 2px solid var(--light-gray);
  border-radius: 12px;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.search-input:focus, .search-input-active {
  border-color: var(--primary-color);
  box-shadow: 0 4px 16px rgba(58, 143, 133, 0.2);
  transform: translateY(-2px);
}

.search-input::placeholder {
  color: var(--medium-gray);
  opacity: 0.8;
}

.search-input-filled {
  background-color: var(--off-white);
  border-color: var(--primary-light);
}

.suggestions-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--white);
  border-radius: 12px;
  border: 1px solid var(--light-gray);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  margin-top: 0.5rem;
  animation: dropdownFade 0.2s ease-out;
}

.suggestions-list {
  padding: 0.5rem 0;
}

.suggestion-item {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: var(--dark-gray);
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}

.suggestion-item:hover {
  background-color: var(--primary-light);
  color: var(--primary-dark);
}

.suggestion-text {
  flex: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
.hero-content {
  padding: 0 1rem;
  }
  
  .hero-subtitle {
    font-size: 1.25rem;
  }

  .search-inputs-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .hero-section {
    padding: 4rem 1rem 3rem;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 2rem;
  }

  .hero-cta {
    padding: 0.75rem 2rem;
    font-size: 1.1rem;
  }

  .message-card {
    padding: 2rem;
  }
}