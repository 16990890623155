.navbar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--dark);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: top 0.3s ease-in-out;
  z-index: 1000;
}

.navbar.hidden {
  top: -100px;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.navbar-logo img {
  height: 30px;
  width: auto;
}

.brand-text {
  color: var(--off-white);
  font-size: 1.2rem;
  font-weight: 700;
  margin-left: 0.5rem;
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  display: none;
}

.navbar-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--off-white);
  cursor: pointer;
}

/* Navigation links */
.navbar-links {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav-link {
  color: var(--off-white);
  text-decoration: none;
  padding: 0.5rem;
  transition: color 0.2s ease;
}

.nav-link:hover {
  color: var(--primary-light);
}

.nav-link.active {
  font-weight: bold;
  color: var(--white);
}

/* Donate button */
.donate-button {
  background-color: var(--secondary-color);
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.donate-button:hover {
  background-color: var(--secondary-dark);
  transform: scale(1.05);
}

/* Responsive Styles */
@media (max-width: 991px) {
  .navbar-toggle {
    display: block;
  }

  .brand-text {
    display: inline;
  }

  .navbar-links {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: var(--dark);
    padding: 1rem;
    display: none;
  }

  .navbar-links.expanded {
    display: flex;
  }

  .donate-button {
    margin-top: 0.5rem;
  }
}
