.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--off-white);
  color: var(--dark);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.main-content {
  flex: 1;
  padding: 1rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .main-content {
    padding: 0.75rem;
  }
}